<template>
  <div class="grid grid-cols-2 items-center px-20px pt-18px">
    <div class="text-ems-gray200 text-16px font-semibold uppercase">
      {{ t('object_information.social_media_link') }}
    </div>
  </div>
  <div class="h-1px w-[calc(100%-40px)] mx-auto bg-ems-gray700 mt-17px"></div>

  <div class="pl-20px pr-10px mr-5px max-h-700px overflow-y-auto">
    <div class="my-16px text-ems-gray200 text-16px font-semibold">
      {{ t('object_information.matching_percent') }}
    </div>
    <a-spin v-if ="isLoading" :tip="t('common.Loading')" class="justify-center flex flex-col items-center"> </a-spin>
    <Empty
      v-if="
        (formMode === FORM_MODE.CREATE ||
        !listDataSocialSimilar ||
        listDataSocialSimilar.length === 0)&& !isLoading
      "
      :description="t('common.no_data_displayed')"
      class="mt-[60px]"
    />
    <div v-else class="grid grid-cols-3 gap-20px">
      <template v-for="item in listDataSocialSimilar">
        <CardSocial :item="item" />
      </template>
    </div>
    <div class="h-1px w-[calc(100%)] mx-auto bg-ems-gray700 my-28px"></div>
    <div class="my-16px text-ems-gray200 text-16px font-semibold">
      {{ t('object_information.list_relate') }}
    </div>
    <a-spin v-if ="isLoading" :tip="t('common.Loading')" class="justify-center flex flex-col items-center"> </a-spin>
    <Empty
      v-if="
        (formMode === FORM_MODE.CREATE || !listDataSocialMayKnown || listDataSocialMayKnown.length === 0) && !isLoading
      "
      :description="t('common.no_data_displayed')"
      class="mt-[60px]"
    />
    <div v-else>
      <template v-for="item in listDataSocialMayKnown">
        <CardSocialMayKnown :item="item" />
      </template>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, ref,onMounted } from 'vue';
import InputFilter from '@/components/input-filter/index.vue';
import SelectFilter from '@/components/select-filter/index.vue';
import CardSocial from './CardSocial.vue';
import CardSocialMayKnown from './CardSocialMayKnown.vue'
import { FORM_MODE } from '@/util/common-constant';
import Empty from '@/components/empty/index.vue';

const { t } = useI18n();
const { state, dispatch } = useStore();
const formMode = computed(() => state.ioManagement.formMode);
const formState = computed(() => state.ioManagement.formState);
const listDataSocialMayKnown = computed(() => state.ioGroup.listDataSocialMayKnown);
const listDataSocialSimilar = computed(() => state.ioGroup.listDataSocialSimilar);
const isLoading = ref(true)
onMounted(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('id');
    await dispatch('ioGroup/getListDataSocial',userId).then(()=>{
      isLoading.value = false;
    });
});


const isShowOptions = ref(false);
const listMatching = [];
const listRelate = ref([]);
</script>
<style scoped>
.shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
}
</style>
